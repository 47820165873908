import React, { useState } from 'react';
import './ConnexionDashboardV2.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../img/logo-campus-france.png';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import toast from 'react-hot-toast';
import axios from 'axios';

const ConnexionDashboardV2 = () => {
    // eslint-disable-next-line
    const [signature, setSignature] = useState();
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [email_empty, set_email_empty] = useState(false);
    const [email_syntax_error, set_email_syntax_error] = useState(false);
    const [password_empty, set_password_empty] = useState(false);
    const [loginInProgress, setLoginInProgress] = useState(false);

    // eslint-disable-next-line
    const onGetUserTalkJSSignature = (talkID, user) => {
        var signature = null
        axios.get(ApiBaseUrl + 'user-talk-signature/' + talkID)
            .then(response => {
                if(response.data.success && response.data.empty === false){
                signature = response.data.signature
                if(user === 'visiteur'){
                    window.sessionStorage.setItem('talkTokenVisiteur', signature);
                    return signature
                }else if(user === 'conseiller'){
                    window.sessionStorage.setItem('talkTokenConseiller', signature);
                    return signature
                }
                setSignature(signature)
                
        
                }else if(response.data.success && response.data.empty === true){
                signature = null
                return false;
                }
            })
    }
    
    const loginHandleChange = (e) => {
        let _fields = fields;
    
        if(e.target.name === 'email'){
          _fields[e.target.name] = (e.target.value).toLowerCase();
        }else{
          _fields[e.target.name] = e.target.value;
        }
        setFields(_fields);
    }

    const onValidateLoginFormData = () => {
        let _errors = errors;
        var isValidForm = true;
    
        if(!fields["email"]){
            isValidForm = false;
            set_email_empty(true);
            setTimeout(() => {
                set_email_empty(false);
            }, 5000);
            _errors["email"] = 'Ce champ email est obligatoire'
        }
        if (typeof fields["email"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                isValidForm = false;
                set_email_syntax_error(true);
                setTimeout(() => {
                    set_email_syntax_error(false);
                }, 5000);
                _errors["email_syntax_error"] = "L'adresse email est invalide.";
            }
        }
        if(!fields["password"]){
          isValidForm = false;
          set_password_empty(true);
          setTimeout(() => {
            set_password_empty(false);
          }, 5000);
          _errors["password"] = 'Ce champ mot de passe est obligatoire'
        }
    
        setErrors(_errors)
    
        return isValidForm;
    }
    
    const onSubmitLoginForm = (e) => {
        e.preventDefault();
    
        if(onValidateLoginFormData()){
            setLoginInProgress(true);

            let headers = new Headers();
        
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Access-Control-Allow-Origin', '*');
            headers.append('Access-Control-Allow-Credentials', 'false');
            headers.append('GET', 'POST', 'OPTIONS');
        
            axios.post(ApiBaseUrl + 'login', fields, headers)
                .then(response => {
                    setLoginInProgress(false);
                    if(response.data.success && response.data.validcredential){
                        window.sessionStorage.setItem('user_id', response.data.logged_user_data.id);
                        window.sessionStorage.setItem('token', response.data.data_token);
                        window.sessionStorage.setItem('user_role', response.data.logged_user_data.role);
                        var staffUserTalkerData = response.data.staffUserTalkerData
                        var conseiller = [];

                        // "success" => true,
                        // 'validcredential' => true,
                        // "logged_user_data" => $login_user,
                        // "data_token" => $token,
                        // 'logged_conseiller_data' => $conseiller,
                        // 'staffUserTalkerData' => $staffUserTalkerData,
                        // "message" => "succes"
            
                        if(response.data.logged_user_data.role === 'admin'){
                            axios.get(ApiBaseUrl + 'user-talk-signature/' + staffUserTalkerData.id)
                                .then(resp => {
                                    if(resp.data.success && resp.data.empty === false){
                                        const userData = { // For chatroom
                                            id: staffUserTalkerData.id,
                                            name: staffUserTalkerData.name,
                                            email: staffUserTalkerData.email,
                                            role: "messager",
                                            photoUrl: staffUserTalkerData.photoUrl,
                                            // signature: resp.data.signature,
                                            info: staffUserTalkerData.info,
                                            userStatus: "admin"
                                        }
                    
                                        window.sessionStorage.setItem('user_avatar', staffUserTalkerData.logo_url)
                                        window.sessionStorage.setItem("currentTalkjsUser", JSON.stringify(userData))
                        
                                        toast.success('Connexion réussie! Vous serez redirigé dans 5s ...', {
                                            duration: 5000,
                                            position: 'top-center',
                                        });
                    
                                        setTimeout(() => {
                                            window.location = 'admin-conseiller-dashboard';
                                        }, 5000);
                                        
                                    }
                                })
                                .catch(error => {
                                    console.log(error)
                                    setLoginInProgress(false);
                                    toast.error('Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.', {
                                        duration: 5000,
                                        position: 'top-center',
                                    });
                                })
                        } 
            
                        if(response.data.logged_user_data.role === 'conseiller-campus-france'){
                            conseiller = response?.data?.logged_conseiller_data;

                            // // eslint-disable-next-line
                            // var conseillerTalkerData = response.data.monEspaceConseillerTalker;
                            // // eslint-disable-next-line
                            // var userConseillerTalkID = conseillerTalkerData.id;

                            // eslint-disable-next-line
                            var alter = onRemoveAccents(conseiller.conseiller_fullname.toLowerCase())
                            // eslint-disable-next-line
                            var strForEmptyAvatar = alter.split(' ').join('+');

                            
                
                            axios.get(ApiBaseUrl + 'user-talk-signature/' + staffUserTalkerData?.id)
                                .then(resp => {
                                if(resp.data.success && resp.data.empty === false){
                                    const userData = { // For chatroom
                                        id: conseiller.id + 'campus-france-senegal' + conseiller.id  + 'conseiller',
                                        name: staffUserTalkerData?.name,
                                        email: staffUserTalkerData?.email,
                                        role: "messager",
                                        photoUrl: staffUserTalkerData?.photoUrl,
                                        // signature: resp.data.signature,
                                        info: "Conseiller à Campus France SN ",
                                        userStatus: "conseiller"
                                    }
                
                                    window.sessionStorage.setItem('user_avatar', conseiller.isEmptyAvatar === 0 ? conseiller.avatar_url :  "https://ui-avatars.com/api/?name=" + strForEmptyAvatar)
                                    // window.sessionStorage.setItem('stand_logo', institution.logo_url)
                                    window.sessionStorage.setItem("currentTalkjsUser", JSON.stringify(userData))
                    
                                    toast.success('Connexion réussie! Vous serez redirigé dans 5s ...', {
                                        duration: 5000,
                                        position: 'top-center',
                                    });
                
                                    setTimeout(() => {
                                        window.location = 'admin-conseiller-dashboard';
                                    }, 5000);
                                }
                                })
                                .catch(error => {
                                    console.log(error)
                                    setLoginInProgress(false);
                                    toast.error('Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.', {
                                        duration: 5000,
                                        position: 'top-center',
                                    });
                                })
                        }
            
                    }else if (response.data.success && response.data.validcredential === false){
                        toast.error("Échec de la connexion. Les informations d'identification et/ou le mot de passe ne sont pas valides", {
                            duration: 5000,
                            position: 'top-center',
                        });
                        console.log(response)
                    }else{
                        toast.error("Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.", {
                            duration: 5000,
                            position: 'top-center',
                        });
                        console.log(response)
                    }
                })
                .catch(error => {
                    setLoginInProgress(false);
                    toast.error("Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.", {
                        duration: 5000,
                        position: 'top-center',
                    });
                    console.log(error.message)
                })
        }
    }
    
    const onRemoveAccents = (strAccents) => {
        var _strAccents = strAccents.split('');
        var strAccentsOut = [];
        var strAccentsLen = _strAccents.length;
        var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž()/';
        var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz---";
        for (var y = 0; y < strAccentsLen; y++) {
            if (accents.indexOf(_strAccents[y]) !== -1) {
                strAccentsOut[y] = accentsOut.substr(accents.indexOf(_strAccents[y]), 1);
            } else {
                strAccentsOut[y] = _strAccents[y];
            }
        }
        strAccentsOut = strAccentsOut.join('');
        console.log(strAccentsOut);
        return strAccentsOut;
    }

    return (
        <div className="dashboard-auth-component">
            <header>
            <div className="col-md-12 d-flex justify-content-center p-block-20 header-navbar">
                <NavLink to="/">
                <img className="logo-inscription" src={Logo} alt="Mon Espace Campus France" />
                </NavLink>
            </div>
            </header>
            <section id="auth-form-section">
                <div className="container auth-form-container">
                    <div className="auth-form-body">
                        <div className="auth-form-content">
                            <h3 className="auth-form-title">Connexion</h3>
                            <form id="auth-login-form">
                                <div className="row auth-form-row">
                                    <div className="col-md-12 auth-input-col">
                                        <div className="auth-input-container form-group">
                                            <input onChange={loginHandleChange} placeholder="Votre adresse email" className="form-control auth-form-control" name="email" type="email" id="email" />
                                        </div>
                                        {email_empty &&
                                            <div className="alert alert-danger error-alert text-center">
                                                {errors.email}
                                            </div>
                                        }
                                        {email_syntax_error &&
                                            <div className="alert alert-danger error-alert text-center">
                                                {errors.email_syntax_error}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-12 auth-input-col">
                                        <div className="auth-input-container form-group">
                                            <input onChange={loginHandleChange} placeholder="Votre mot de passe" className="form-control auth-form-control" name="password" type="password" id="password" />
                                        </div>
                                        {password_empty &&
                                            <div className="alert alert-danger error-alert text-center">
                                                {errors.password}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-12 auth-submit-btn-col">
                                        {
                                            loginInProgress ?
                                                <button className="btn btn-success auth-submit-btn">
                                                    connexion en cours
                                                </button> :
                                                <button type="submit" onClick={(e) => onSubmitLoginForm(e)} className="btn btn-success auth-submit-btn">se connecter</button>
                                        }
                                    </div>
                                    <div className="col-md-12 auth-forget-password-link-col">
                                        <NavLink to="/mot-de-passe-oublie" className="btn btn-default auth-forget-password-link">mot de passe oublié ?</NavLink>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            
        </div>
    )
}

export default ConnexionDashboardV2