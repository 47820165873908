import React from 'react';
import { NavLink } from "react-router-dom";
import { Activity, Calendar, Category, Chart, Chat, Document, InfoSquare, Message, Paper, People, Setting, Video, Ticket, AddUser, Image, Folder } from "react-iconly";

const NavVertical = () => {

    const userRole = window.sessionStorage.getItem('user_role');

    return(
        <div className="navbar-vertical-customisation-v2 no-view-mobile">
            <div className="hoverable">
                <ul className="nav nav-items-custom flex-column">
                    <li className="nav-item nav-item-vertical-custom">
                        <NavLink
                            className="nav-link nav-link-vertival-custom"
                            to="/admin-conseiller-dashboard"
                            activeClassName="actived"
                        >
                            <Chart
                                set="bold"
                                primaryColor="#273375"
                                className="icon-navbar-vertical"
                            />
                            <span className="hiddenable-navlink-label">
                                Dashboard
                            </span>
                        </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                        <NavLink
                            className="nav-link nav-link-vertival-custom"
                            to="/configurations"
                            activeClassName="actived"
                        >
                            <Setting
                                set="bold"
                                primaryColor="#273375"
                                className="icon-navbar-vertical"
                            />
                            <span className="hiddenable-navlink-label">
                                Configuration
                            </span>
                        </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                        <NavLink
                            className="nav-link nav-link-vertival-custom"
                            to="/boite-de-reception-messagerie"
                            activeClassName="actived"
                        >
                            <Chat
                                set="bold"
                                primaryColor="#273375"
                                className="icon-navbar-vertical"
                            />
                            <span className="hiddenable-navlink-label">
                                Messagerie
                            </span>
                        </NavLink>
                    </li>
                    
                    <li className="nav-item nav-item-vertical-custom">
                        <NavLink
                            className="nav-link nav-link-vertival-custom"
                            to="/gestionnaire-des-donnees-auto-evaluation"
                            activeClassName="actived"
                        >
                            <Folder
                                set="bold"
                                primaryColor="#273375"
                                className="icon-navbar-vertical"
                            />
                            <span className="hiddenable-navlink-label">
                                Auto-Evaluation
                            </span>
                        </NavLink>
                    </li>
                    {userRole === 'admin' &&
                        <>
                            <li className="nav-item nav-item-vertical-custom">
                                <NavLink
                                    className="nav-link nav-link-vertival-custom"
                                    to="/liste-des-conseillers"
                                    activeClassName="actived"
                                >
                                    <People
                                        set="bold"
                                        primaryColor="#273375"
                                        className="icon-navbar-vertical"
                                    />
                                    <span className="hiddenable-navlink-label">
                                        Gestion du staff
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav-item nav-item-vertical-custom">
                                <a
                                    className="nav-link nav-link-vertival-custom"
                                    data-toggle="collapse"
                                    href="#collapseMenuLink_1"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseMenuLink_1"
                                >
                                    <Document
                                        set="bold"
                                        primaryColor="#273375"
                                        className="icon-navbar-vertical"
                                    />
                                    <span className="hiddenable-navlink-label">
                                        Gestion des données
                                        <i
                                            className="fas fa-caret-down"
                                            style={{ marginLeft: "10px" }}
                                        ></i>
                                    </span>
                                </a>
                                <ul
                                    className="collapse nav flex-column ms-1"
                                    id="collapseMenuLink_1"
                                    // data-parent="#menu"
                                    // style={{ marginLeft: "1rem" }}
                                >
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/gestion-des-villes"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Category
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Gestion des antennes
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/seance-orientation-remplissage-manager"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Calendar
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Gestion des RDV
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/notifications-inscription-seances"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Message
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Emails envoyés
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/liste-des-prises-en-charge"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Activity
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Prises en charge
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/tutoriel-videos-manager"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Video
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Tutoriels vidéo
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/articles-manager"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Paper
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Gestion des articles
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/liste-des-liens-generees"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Paper
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Liens générés
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/je-minforme-section-manager"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <InfoSquare
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Je m'informe
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/events-manager"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Ticket
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Événements
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/newsletters-manager"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <AddUser
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Newsletters
                                            </span>
                                        </NavLink> 
                                    </li>
                                    <li className="nav-item collapsed-item w-100">
                                        <NavLink
                                            to="/sliders-manager"
                                            className="nav-link nav-link-vertival-custom"
                                            activeClassName="actived"
                                        >
                                            <Image
                                                set="bold"
                                                primaryColor="#273375"
                                                className="icon-navbar-vertical"
                                            />
                                            <span className="hiddenable-navlink-label">
                                                Sliders
                                            </span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </>

                    }
                </ul>


            </div>
        </div>
    );
}

export default NavVertical;
