// TalkJS AppID LOCAL
// var talkjsID = 'tM9Ecn0s';

// TalkJS AppID DEMO
var talkjsID = "tM9Ecn0s";

// TalkJS AppID PRODUCTION
// var talkjsID = 'ZbVYWEk8';

export default talkjsID;
