import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';
import './headerOutilsEtudiant.css';
import Logo from '../../img/logo-campus-france.png';
import ZoomIcon from '../../img/icons/zoom.svg'
import { NavLink } from 'react-router-dom';
import axios from 'axios'
import ApiBaseUrl from '../../http/ApiBaseUrl';

export default class HeaderOutilsEtudiant extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userRole: window.sessionStorage.getItem('user_role'),
          onShowModalInfos: false,
          configs: [],
          infosUtile: [],
          zoomInfos: null,
          youtubeInfos: null
        };
    }

    componentDidMount(){
      this.onGetConfigurations();

      setTimeout(() => {
        if(this.state.configs.some(item => item.key === "ZOOM-INFOS" && item.is_active === true)){
          this.state.configs.forEach(element => {
            if(element.key === "ZOOM-INFOS"){
              this.setState({
                zoomInfos: element
              })
            }
          });
        }

        if(this.state.configs.some(item => item.key === "YOUTUBE-LIVE" && item.is_active === true)){
          this.state.configs.forEach(element => {
            if(element.key === "YOUTUBE-LIVE"){
              this.setState({
                youtubeInfos: element
              })
            }
          });
        }
        
      }, 2000);
    }

    onGetConfigurations = () => {
      

      axios.get(`${ApiBaseUrl}configurations`)
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          if(response.data.success){
            // console.log(response.data.data)
            this.setState({
              configs: response.data.data
            })
          }else{
            console.log(response)
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false
          })
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    openLinkInNewTab(url){
      window.open(url, '_blank');
    }

    render() {
      return(
        <nav className="navbar navbar-light bg-white box-shadow fixed-top">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img className="logo-header" src={Logo} alt="Campus France" />
            </NavLink>
            <div className="live_btn_container">
              {this.state.zoomInfos !== null && this.state.zoomInfos.link !== null ?
                <button 
                  className="btn btn-primary oe-live-btn"
                  onClick={() => this.openLinkInNewTab(this.state.zoomInfos.link)}
                >
                  <span className="live-label">WEBINAIRE</span> &nbsp;
                  <span className="live-icon-container">
                    {/* <FeatherIcon icon="wifi" className="live-icon" /> */}
                    <img src={ZoomIcon} className="zoom-icon" alt="zoom" />
                  </span>
                </button> : 

                <button className="btn btn-danger oe-live-btn">
                  <span className="live-label">WEBINAIRE</span> &nbsp;
                  <span className="live-icon-container">
                    <FeatherIcon icon="wifi-off" className="live-icon" />
                    {/* <img src={ZoomIcon} className="zoom-icon" alt="zoom" /> */}
                  </span>
                </button> 
              }

              {this.state.youtubeInfos !== null &&
                <button 
                  className="btn btn-warning oe-live-btn"
                  onClick={() => this.openLinkInNewTab(this.state.youtubeInfos.link)}
                >
                  <span className="live-label">LIVE YOUTUBE</span> &nbsp;
                  <span className="live-icon-container">
                    <FeatherIcon icon="youtube" className="live-icon" />
                  </span>
                </button>
              }

            </div>
            {this.props.isAdminDashboard === false ?
              <div className="account-access-link-container">
                <a target="_blank" rel="noopener noreferrer" className="btn-access-compte no-view-mobile" href="https://pastel.diplomatie.gouv.fr/etudesenfrance/dyn/public/login.html?ecf=SN&locale=fr_SN">
                  <FeatherIcon className="icon-access-compte-mobile" icon="user" />&nbsp;
                  Accèder à mon compte études en France
                </a>
              </div> :
              <h1 className="admin-dashboard-title">Dashboard {this.state.userRole === 'admin' ? 'Admin' : 'Conseiller'} </h1>
            }
          </div>
        </nav>
      );
    }
  }
