import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import './index.css';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log('dev mode')
} else {
  // Sentry.init({
  //   dsn: "https://6c63f479b99a44709f7e53e1382df446@app-sentry.withvolkeno.com/63",
  //   autoSessionTracking: true,
  //   integrations: [
  //     new Integrations.BrowserTracing(),
  //   ],
  
  //   // We recommend adjusting this value in production, or using tracesSampler
  //   // for finer control
  //   tracesSampleRate: 1.0,
  // });

  const tagManagerArgs = {
    gtmId: 'GTM-K4BTW4K'
  }
  
  TagManager.initialize(tagManagerArgs)

  // Google Analytics
  ReactGA.initialize('UA-98137027-8');
  ReactGA.pageview(window.location.pathname + window.location.search);
}





document.addEventListener('DOMContentLoaded', function () {
    ReactDOM.render(
      <App />, document.getElementById('root')
    );
});

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
